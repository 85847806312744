<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col md="6">
          <h1 class="mr-sm-4 header-main text-uppercase">จัดการคอลเล็คชั่น</h1>
        </b-col>
        <b-col md="6" class="text-right">
          <div class="d-flex justify-content-end w-100">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="ค้นหา"
                v-model="filter.Search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
            <b-button @click="edit()" class="btn-main p-2"
              >สร้างคอลเล็คชั่น</b-button
            >
          </div>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white px-0">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
              class="table-list"
            >
              <template v-slot:cell(productCount)="data">
                <span v-if="data.item.productCount">{{
                  data.item.productCount
                }}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(createdTime)="data">
                <span class="text-nowrap">{{
                  $moment(data.item.createdTime).format($formatDateTimeFull)
                }}</span>
              </template>
              <template v-slot:cell(updatedTime)="data">
                <span class="text-nowrap">{{
                  $moment(data.item.updatedTime).format($formatDateTimeFull)
                }}</span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <div>
                    <b-form-checkbox
                      switch
                      class="radio-active ml-3"
                      size="lg"
                      v-model="data.item.status"
                      @change="changeStatus($event, data.item.id)"
                    >
                      <span class="ml-2 main-label">
                        {{ data.item.status ? "เปิด" : "ปิด" }}
                      </span>
                    </b-form-checkbox>
                  </div>
                  <b-button
                    variant="link"
                    class="text-dark text-nowrap pl-3 py-1"
                    @click="edit(data.item.id)"
                  >
                    ดูรายละเอียด
                  </b-button>
                  <b-button
                    @click="openModalDelete(data.item)"
                    variant="link"
                    class="text-dark text-nowrap pl-3 py-1"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalLoading from "@/components/modal/alert/ModalLoading";

export default {
  name: "CategoryNews",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
  },
  data() {
    return {
      modalMessage: "",
      id: "",
      requestDeleteBanner: {
        id: null,
      },
      fields: [
        {
          key: "collectionID",
          label: "Collection ID",
          class: "w-100px",
        },
        {
          key: "name",
          label: "ชื่อคอลเลคชั่น",
          class: "w-100px",
        },
        {
          key: "productCount",
          label: "จำนวนรายการสินค้า",
          class: "w-100",
        },
        {
          key: "createdTime",
          label: "วันที่สร้าง",
          class: "w-100px",
        },
        {
          key: "updatedTime",
          label: "อัพเดทล่าสุด",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        status: [0, 1],
        search: "",
        PageNo: 1,
        PerPage: 10,
        ObjectId: 4, // Product
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      isDisable: false,
      timer: null,
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/List`,
        null,
        this.$headersSetting,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    changeStatus: async function (status, id) {
      this.$refs.modalLoading.show();
      let body = {
        id: id,
        status: status,
      };

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/Active`,
        null,
        this.$headersSetting,
        body
      );
      this.$refs.modalLoading.hide();

      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
    edit(id) {
      this.$store.commit("setTemplateId", null);
      if (id) {
        this.$router.push({
          path: `/setting-product/details/${id}`,
        });
      } else {
        this.$router.push({
          path: `/setting-product/details/0`,
        });
      }
    },
    pagination(Page) {
      window.scrollTo(0, 0);
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
    openModalDelete(value) {
      this.requestDeleteBanner.id = value.id;
      this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/Template/Section/${this.requestDeleteBanner.id}`,
        null,
        this.$headersSetting,
        null
      );

      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.PageNo = 1;
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 2000);
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>
